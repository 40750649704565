import React, {useEffect, useState} from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  CardMedia,
} from "@material-ui/core";

import Link from "../components/Link";
import Layout from "../components/Layout";

import {
  renderCartItem,
  renderColor,
  generateUrlForClipboard,
  parseUrl,
} from "../utils/helpers";

import {sendEmail} from "../utils/db";

import defaultImg from "../images/imageEle.png";
import multiPocketImg from "../images/pocket.jpg";
import pantsPocketLogoImg from "../images/logo_pocket.png";
import pantsBackPocketLogoImg from "../images/logo_back_pocket.png";
import backJacketLogoImg from "../images/back_logo.jpg";
import frontJacketlogoImg from "../images/front_logo.jpg";
import frontJacketPocketLogoImg from "../images/pocketLogoCart.png";
import chestLogoImg from "../images/chestLogo.png";
import stripsImg from "../images/stripes.jpg";
import mischgewebeImg from "../images/mat1.jpg";
import baumwolleVerstarktImg from "../images/mat2.jpg";
import esceladeImg from "../images/escelade.jpg";
import sotinaImg from "../images/sotina.jpg";
import sotinaplusImg from "../images/sotinaplus.jpg";
import polarFleece230Img from "../images/polarfleece230.jpg";
import polarFleece280Img from "../images/polarfleece280.jpg";
import softshellImg from "../images/softshell.jpg";
import corduraImg from "../images/cordura.png";

import {PRICE} from "../utils/prices";
import {useLocation} from "@reach/router";

export default function Shared() {
  const classes = useStyles();
  const location = useLocation();

  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [emailMessage, setEmailMessage] = useState("Have a nice day!");
  const [emailTo, setEmailTo] = useState("");
  const [senderName, setSenderName] = useState("");
  const [emailSent, setEmailSent] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const generateUrl = async (items, e) => {
    if (emailTo === "") return "Email is required.";
    if (senderName === "") return "Name is required.";

    e.preventDefault();

    const url = generateUrlForClipboard(items);
    setGeneratedUrl(url);

    const res = await sendEmail({
      collection_url: url,
      from_name: senderName,
      message: emailMessage,
      email_to: emailTo,
      href: location.origin,
    });

    if (res.status === 200) {
      setEmailSent("Successful!");
    } else {
      setEmailSent("Email not sent, please check your data.");
    }
  };

  const getExtraOptionImage = (name) => {
    switch (name) {
      case "Rechte Multifunktionstasche":
        return multiPocketImg;
      case "Leuchtstreifen an den Beinen":
        return stripsImg;
      case "Stickerei auf Beintasche links":
        return pantsPocketLogoImg;
      case "Stickerei auf Geässtsache links":
        return pantsBackPocketLogoImg;
      case "Stickerei auf Brusttasche":
        return chestLogoImg;
      case "Stickerei auf Brusttasche links":
        return frontJacketPocketLogoImg;
      case "Logo auf der Rückseite":
        return backJacketLogoImg;
      case "Logo auf der Vorderseite":
        return frontJacketlogoImg;
      case "Brusttasche Links":
        return frontJacketPocketLogoImg;
      case "Material: Mischgewebe":
        return mischgewebeImg;
      case "Material: Baumwolle verstärkt":
        return baumwolleVerstarktImg;
      case "Material: Ripstop":
        return mischgewebeImg;
      case "Material: Escelade":
        return esceladeImg;
      case "Material: Sotina":
        return sotinaImg;
      case "Material: Sotina Plus":
        return sotinaplusImg;
      case "Material: Softshell":
        return softshellImg;
      case "Material: Polar Fleece 230":
        return polarFleece230Img;
      case "Material: Polar Fleece 280":
        return polarFleece280Img;
      case "Cordura":
        return corduraImg;

      default:
        return defaultImg;
    }
  };

  const getExtraOptionPrice = (name) => {
    switch (name) {
      case "Rechte Multifunktionstasche":
        return PRICE.multipocket;
      case "Leuchtstreifen an den Beinen":
        return PRICE.reflectivestrips;
      case "Stickerei auf Beintasche links":
        return PRICE.logo;
      case "Stickerei auf Geässtsache links":
        return PRICE.logo;
      case "Stickerei auf Brusttasche":
        return PRICE.logo;
      case "Stickerei auf Brusttasche links":
        return PRICE.logo;
      case "Logo auf der Rückseite":
        return PRICE.logo;
      case "Logo auf der Vorderseite":
        return PRICE.logo;
      case "Brusttasche Links":
        return PRICE.logo;
      case "Mischgewebe":
        return 0;
      case "Cordura":
        return PRICE.cordura;

      default:
        return 0;
    }
  };

  useEffect(() => {
    async function getData() {
      const res = await parseUrl();

      setCartItems(res);

      res.map((item) => {
        if (item === "Bad url!") {
          setCartItems([]);
          return null;
        }
        return null;
      });
    }

    getData();
  }, []);

  useEffect(() => {
    try {
      setQuantity(cartItems.reduce((a, b) => +a + +b.quantity, 0));
      setTotalPrice(cartItems.reduce((a, b) => +a + +b.totalPrice, 0));
    } catch (error) {}
  }, [cartItems]);

  return (
    <Layout>
      <Grid
        container
        align="center"
        justify="center"
        direction="row"
        className={classes.root}
      >
        <Grid align="center" item sm={12} lg={9} md={9} xl={9} xs={12}>
          <Typography className={classes.title} noWrap align="center" variant="h4">
            Geteilte Auswahl
          </Typography>

          <Divider
            style={{
              paddingTop: "3px",
              marginBottom: "10px",
            }}
          />
          <Grid
            container
            align="center"
            justify="center"
            direction="row"
            className={classes.root}
          >
            <Grid align="center" item sm={1} lg={1} md={1} xl={1} xs={1}></Grid>
            {/* ITEMS START */}
            <Grid
              style={{
                background: "#ffffff",
              }}
              align="left"
              item
              sm={12}
              lg={9}
              md={9}
              xl={9}
              xs={12}
            >
              {cartItems.map((item, id) => {
                return (
                  <Grid border={1} className={classes.cartItem} container key={id}>
                    <Grid item sm={12} lg={3} md={3} xl={3} xs={12}>
                      {renderCartItem(classes.printStyle, item)}
                    </Grid>
                    <Grid
                      align="center"
                      item
                      container
                      sm={12}
                      lg={9}
                      md={9}
                      xl={9}
                      xs={12}
                    >
                      {/*==================== Title ==================== */}
                      <Grid item sm={12} lg={12} md={12} xl={12} xs={12}>
                        <Typography
                          className={classes.garmentName}
                          noWrap
                          align="left"
                          variant="h4"
                        >
                          {item.garmentName}
                        </Typography>
                        <Divider
                          style={{
                            paddingTop: "3px",
                            marginBottom: "10px",
                          }}
                        />
                      </Grid>
                      {/*==================== EXTRA OPTIONS ====================*/}
                      <Grid item sm={12} lg={12} md={12} xl={12} xs={12}>
                        <Typography
                          className={classes.optionsTitle}
                          noWrap
                          align="left"
                          variant="h5"
                        >
                          Zusätzliche Optionen:
                        </Typography>
                        <Box display="flex" flexWrap="wrap" flexDirection="row">
                          {item.extraOptions
                            ? item.extraOptions.map((extra, id) => {
                                return (
                                  <Box border={1} key={id} className={classes.cardBox}>
                                    <CardMedia
                                      className={classes.cardImage}
                                      image={getExtraOptionImage(extra)}
                                      src=""
                                    ></CardMedia>
                                    <Typography
                                      align="left"
                                      variant="body2"
                                      key={extra}
                                      style={{maxWidth: "60%"}}
                                    >
                                      {extra} <br />
                                      {parseFloat(getExtraOptionPrice(extra)).toFixed(2) +
                                        " CHF"}
                                    </Typography>
                                  </Box>
                                );
                              })
                            : null}
                        </Box>
                      </Grid>
                      {/*==================== Prices / color ==================== */}
                      <Grid item sm={12} lg={12} md={12} xl={12} xs={12}>
                        <Typography
                          className={classes.specsText}
                          align="left"
                          variant="subtitle2"
                        >
                          Farbwahl:
                        </Typography>

                        <Typography
                          className={classes.specsText}
                          align="left"
                          variant="subtitle2"
                        >
                          Grundfarbe: {renderColor(item.lightContrast)}
                        </Typography>
                        {item.hasContrast ? (
                          <Typography
                            className={classes.specsText}
                            align="left"
                            variant="subtitle2"
                          >
                            Kontrast: {renderColor(item.darkContrast)}
                          </Typography>
                        ) : null}
                        <Typography
                          className={classes.specsText}
                          align="left"
                          variant="subtitle2"
                        >
                          Nähte: {renderColor(item.stitching)}
                        </Typography>
                        {item.hasPatch ? (
                          <Typography
                            className={classes.specsText}
                            align="left"
                            variant="subtitle2"
                          >
                            Knieverstärkung: {renderColor(item.patchColor)}
                          </Typography>
                        ) : null}

                        <Divider
                          style={{
                            paddingTop: "3px",
                            marginBottom: "10px",
                          }}
                        />
                        <Typography
                          className={classes.specsText}
                          align="left"
                          variant="subtitle2"
                        >
                          Menge: {item.quantity} <br />
                          Preis/Pcs: {parseFloat(item.price).toFixed(2)} CHF <br />
                        </Typography>

                        <Typography
                          className={classes.specsText}
                          align="left"
                          variant="h6"
                        >
                          Total: {parseFloat(item.totalPrice).toFixed(2)} CHF
                        </Typography>
                      </Grid>
                    </Grid>

                    {/*==================== DIVIDER ====================*/}
                    <Grid item sm={12} lg={12} md={12} xl={12} xs={12}>
                      <Divider
                        style={{
                          marginTop: "10px",
                          paddingTop: "3px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            {/* ITEMS END */}

            <Grid align="center" item sm={1} lg={1} md={1} xl={1} xs={1}></Grid>
          </Grid>
        </Grid>
        {/*START SIDE GRID */}
        <Grid
          className={classes.sideGrid}
          align="center"
          item
          sm={12}
          lg={3}
          md={3}
          xl={3}
          xs={12}
        >
          <Typography className={classes.inquiryText} variant="h4">
            Anfrage
          </Typography>
          <Typography className={classes.inquiryText} variant="subtitle1">
            Datum: {new Date().toJSON().slice(0, 10).replace(/-/g, "/")}
          </Typography>
          <Divider
            style={{
              background: "#DE2D47",
              marginTop: "10px",
              paddingTop: "3px",
            }}
          />
          <Grid container>
            <Grid item sm={1} lg={1} md={1} xl={1} xs={1}></Grid>
            <Grid item sm={10} lg={10} md={10} xl={10} xs={10}>
              <Grid container>
                <Grid item sm={5} lg={5} md={5} xl={5} xs={5}>
                  <Typography
                    noWrap
                    align="left"
                    className={classes.inquiryText}
                    variant="subtitle1"
                  >
                    Menge
                  </Typography>
                  <Typography
                    noWrap
                    align="left"
                    className={classes.inquiryText}
                    variant="subtitle1"
                  >
                    Produktionszeit
                  </Typography>
                </Grid>
                <Grid item sm={2} lg={2} md={2} xl={2} xs={2}></Grid>
                <Grid item sm={5} lg={5} md={5} xl={5} xs={5}>
                  <Typography
                    className={classes.inquiryText}
                    align="right"
                    variant="subtitle1"
                    noWrap
                  >
                    {quantity} Pcs
                  </Typography>
                  <Typography
                    className={classes.inquiryText}
                    align="right"
                    variant="subtitle1"
                    noWrap
                  >
                    1-2 monate
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={1} lg={1} md={1} xl={1} xs={1}></Grid>
          </Grid>
          <Divider
            style={{
              width: "100%",
              background: "#DE2D47",
              marginTop: "10px",
              paddingTop: "3px",
              marginBottom: "15px",
            }}
          />
          <Typography className={classes.inquiryText} align="center" variant="h4">
            Gesamtpreis: {parseFloat(totalPrice).toFixed(2)}
          </Typography>
          <Typography className={classes.inquiryText} variant="subtitle1">
            monate
          </Typography>
          <Button
            onClick={(e) => window.print(e)}
            className={classes.inquiryButton}
            color="secondary"
            variant="contained"
          >
            Druckauswahl
          </Button>
          <div>
            <Button
              onClick={handleClickOpen}
              className={classes.inquiryButton}
              color="secondary"
              variant="contained"
            >
              Per E-Mail gesendet
            </Button>

            {/* <Button
              variant="outlined"
              color="secondary"
              onClick={handleClickOpen}
              style={{ marginTop: '20px', width: '90%' }}
            >
              Per E-Mail gesendet
            </Button> */}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">
                Teile deine Auswahl mit uns
              </DialogTitle>
              <form onSubmit={(e) => generateUrl(cartItems, e)}>
                <DialogContent>
                  <DialogContentText>
                    Bitte geben Sie Ihren Namen und Ihre E-Mail-Adresse ein und klicken
                    Sie auf SENDEN. Ihre Auswahl wird an uns gesendet und Sie werden
                    kontaktiert.
                  </DialogContentText>

                  <TextField
                    onChange={(e) => setSenderName(e.target.value)}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Dein Name"
                    type="string"
                    fullWidth
                    required
                  />
                  <TextField
                    onChange={(e) => setEmailTo(e.target.value)}
                    margin="dense"
                    id="email"
                    label="deine Emailadresse"
                    type="email"
                    fullWidth
                    required
                  />
                  <TextField
                    onChange={(e) => setEmailMessage(e.target.value)}
                    margin="dense"
                    id="message"
                    label="Botschaft"
                    type="string"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Typography style={{color: "red", marginRight: "50px"}}>
                    {emailSent}
                  </Typography>
                  <Button onClick={handleClose} color="primary">
                    Stornieren
                  </Button>
                  <Button
                    onClick={(e) => generateUrl(cartItems, e)}
                    color="primary"
                    type="submit"
                  >
                    Senden
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          </div>

          <Link to={"/" + generatedUrl}>
            <Typography
              noWrap
              style={{
                background: "#141414",
                color: "#ffffff",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              variant="h6"
            >
              {generatedUrl ? "LINK" : null}
            </Typography>
          </Link>
          {/*END SIDE GRID */}
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#white",
  },
  clipboardIcon: {},
  specsText: {
    paddingLeft: theme.spacing(1),
  },
  cardImage: {
    height: "100px",
    width: "100px",
    "@media print": {
      display: "none",
      height: "50ox",
      width: "50ox",
    },
  },
  cartItem: {
    width: "100%",
    borderStyle: "dotted",
    marginBottom: "5px",
    "@media print": {
      // marginBottom: '280px',
      width: "100vw",
      height: "98.5vh",
    },
  },
  printStyle: {
    "@media print": {
      width: "50%",
      height: "50%",
    },
  },
  hideItem: {
    "@media print": {
      display: "none",
    },
  },
  deleteButton: {
    marginLeft: "10px",
    width: "90%",
    "@media print": {
      display: "none",
    },
  },
  inquiryButton: {
    "@media print": {
      display: "none",
    },
    marginTop: "15px",
    paddingTop: "15px",
    paddingBottom: "15px",
    width: "80%",
  },
  sideGrid: {
    "@media print": {
      //display: "none",
      marginTop: "300px",
      breakInside: "avoid",
    },
    background: "black",
    minHeight: "89.9vh",
    [theme.breakpoints.down("sm")]: {
      minHeight: "35vh",
    },
  },
  inquiryText: {
    color: "white",
    "@media print": {
      color: "black",
    },
  },
  heroButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  title: {
    background: "#F9F9F9",
    paddingTop: "15px",
    paddingBottom: "20px",
    color: "black",
    "@media print": {
      display: "none",
    },
  },
  garmentName: {
    background: "#ffffff",
    marginTop: "5px",
    marginBottom: "5px",
    paddingLeft: "20px",
    "@media print": {
      margin: "0px",
      padding: "0px",
    },
  },
  cardBox: {
    width: "180px",
    height: "180px",
    marginRight: "2px",
    marginBottom: "2px",
    "@media print": {
      margin: "0px",
      padding: "0px",
      width: "180px",
      height: "90px",
    },
  },
  optionsTitle: {
    background: "#ffffff",
    marginBottom: "10px",
    paddingLeft: "20px",
    "@media print": {
      margin: "0px",
      padding: "0px",
    },
  },
}));
